const dev = {
  API_ENDPOINT_URL: 'https://vivifiapitest.riolabz.com/api/v1/',
  API_DOMAIN: 'https://vivifiapitest.riolabz.com'
};

const prod = {
  API_ENDPOINT_URL: 'https://vivifiapitest.riolabz.com/api/v1/',
  API_DOMAIN: 'https://vivifiapitest.riolabz.com'
};

const test = {
  API_ENDPOINT_URL: 'https://vivifiapitest.riolabz.com/api/v1/',
  API_DOMAIN: 'https://vivifiapitest.riolabz.com'
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev;
    case 'production':
      return prod;
    case 'test':
      return test;
    default:
      break;
  }
};

export const env = getEnv();
