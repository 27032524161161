import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';

const PublicRoute = () => {
  const { token } = useSelector((state) => state.auth);
  console.log("token in PublicRoute", token)

  return token && !window.location.pathname.includes('reports') ? (
    <Navigate to={AUTHENTICATED_ENTRY} />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
