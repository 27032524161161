import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY, REDIRECT_COMPONENT_KEY } from 'configs/AppConfig';
import {
  protectedRoutes,
  publicRoutes,
  thirdPartyRoutes
} from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import { useSelector } from 'react-redux';
import {
  ADMIN_TYPE,
  AUTHENTICATED_CLIENT_ENTRIES,
  AUTHENTICATED_CLIENT_EXECUTIVE_ENTRIES,
  AUTHENTICATED_CUSTOMER_ENTRIES,
  AUTHENTICATED_THIRD_PARTY_ENTRIES,
  CLIENT_EXECUTIVE_TYPE,
  CLIENT_TYPE,
  CUSTOMER_TYPE,
  EMPLOYEE_TYPE,
  THIRD_PARTY_EMPLOYEE_TYPE,
  THIRD_PARTY_TYPE
} from 'constants/AuthConstant';

const Routes = () => {
  const { type, roles } = useSelector((state) => state.auth);

  return (
    <RouterRoutes>
      <Route path='/' element={<ProtectedRoute />}>
        <Route
          path='*'
          element={<Navigate replace to={AUTHENTICATED_ENTRY} />}
        />
        {protectedRoutes.map((route, index) => {
          // if (
          //   route.isPage ||
          //   type === ADMIN_TYPE ||
          //   route.key === REDIRECT_COMPONENT_KEY ||
          //   ((type === EMPLOYEE_TYPE || type === THIRD_PARTY_EMPLOYEE_TYPE) &&
          //     roles[route.key]?.fetch) ||
          //   (type === CUSTOMER_TYPE &&
          //     AUTHENTICATED_CUSTOMER_ENTRIES.includes(route.key)) ||
          //   (type === CLIENT_TYPE &&
          //     AUTHENTICATED_CLIENT_ENTRIES.includes(route.key)) ||
          //   (type === THIRD_PARTY_TYPE &&
          //     AUTHENTICATED_THIRD_PARTY_ENTRIES.includes(route.key)) ||
          //   (type === CLIENT_EXECUTIVE_TYPE &&
          //     AUTHENTICATED_CLIENT_EXECUTIVE_ENTRIES.includes(route.key))
          // ) {
          //   return (
          //     <Route
          //       key={route.key + index}
          //       path={route.path}
          //       element={
          //         <AppRoute
          //           routeKey={route.key}
          //           component={route.component}
          //           {...route.meta}
          //         />
          //       }
          //     />
          //   );
          // }
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}

        {/* {type === THIRD_PARTY_TYPE &&
          thirdPartyRoutes.map((route, index) => (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          ))} */}
        {/* <Route path="*" element={<Navigate to={'/'} replace />} /> */}
      </Route>
      <Route path='/' element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  blankLayout={!!route.blankLayout}
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
