import React from 'react';
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  REDIRECT_COMPONENT_KEY
} from 'configs/AppConfig';
import * as routeKeys from 'constants/RoutesKeys';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import('views/auth-views/authentication/login'))
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    )
  },
  {
    key: 'VehicleInspectionReportKey',
    path: `/reports/inspection/:certificationId`,
    blankLayout: true,
    component: React.lazy(() =>
      import('views/app-views/pages/vehicle-inspection-report')
    )
  }
  // {
  //   key: 'register',
  //   path: `${AUTH_PREFIX_PATH}/register`,
  //   component: React.lazy(() =>
  //     import('views/auth-views/authentication/register')
  //   )
  // },
];

export const protectedRoutes = [
  {
    key: 'pages',
    isPage: true,
    path: `${APP_PREFIX_PATH}/pages`,
    component: React.lazy(() => import('views/app-views/pages'))
  },
  {
    key: 'pages.profile',
    isPage: true,
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import('views/app-views/pages/profile'))
  },
  {
    key: REDIRECT_COMPONENT_KEY,
    path: `${APP_PREFIX_PATH}/dashboards`,
    component: React.lazy(() => import('views/app-views/dashboards/dashboard'))
  },
  {
    key: routeKeys.UserKey,
    path: `${APP_PREFIX_PATH}/dashboards/user-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/user'))
  },
  {
    key: routeKeys.SettingsKey,
    path: `${APP_PREFIX_PATH}/dashboards/settings-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/settings'))
  },
  {
    key: routeKeys.EnquiryKey,
    path: `${APP_PREFIX_PATH}/dashboards/enquiry-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/enquiry'))
  },
  {
    key: routeKeys.CategoryKey,
    path: `${APP_PREFIX_PATH}/dashboards/category-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/category'))
  },
  {
    key: routeKeys.PageKey,
    path: `${APP_PREFIX_PATH}/dashboards/page-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/page'))
  },
  {
    key: routeKeys.ProductsKey,
    path: `${APP_PREFIX_PATH}/dashboards/products-list/*`,
    component: React.lazy(() => import('views/app-views/dashboards/products'))
  },
  // {
  //   key: routeKeys.InventoryAgentKey,
  //   path: `${APP_PREFIX_PATH}/dashboards/inventory-agent-list/*`,
  //   component: React.lazy(() =>
  //     import('views/app-views/dashboards/inventory-agent')
  //   )
  // },


];
